import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Box,
  Paper,
  Modal,
  Alert,
  Tooltip as Tooltips,
  Fab,
  Stepper,
  Step,
  StepLabel,
  Grid,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { blue } from "@mui/material/colors";
import gpbsLogo from "../assets/gpbs-whitelogo.jpg";
import voucher from "../assets/voucher.jpg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const questions = [
  {
    question: "ธุรกิจบริหารจัดการความพึงพอใจของลูกค้าเพียงใด",
    category: "Customer",
    MainCategory: "MKT",
  },
  {
    question:
      "ธุรกิจได้มีการใช้หลักการนำความรู้ด้านปัจจัยมนุษย์ไปประยุกต์ใช้ในการออกแบบ (Human Factors Engineering Design) เข้ามาวางแผนในการออกแบบสินค้า/บริการเพียงใด",
    category: "Customer",
    MainCategory: "MKT",
  },
  {
    question:
      "ธุรกิจใช้แบบสํารวจและตัวชี้วัดความพึงพอใจของลูกค้าเพื่อกําหนดเป้าหมาย และจัดลําดับความสําคัญด้านโอกาสในการปรับปรุงผลิตภัณฑ์/บริการเพียงใด",
    category: "Customer",
    MainCategory: "MKT",
  },
  {
    question:
      "ธุรกิจมีแนวคิดการดําเนินงานทําให้ผลิตภัณฑ์/บริการ มียอดขายคงที่และสามารถทํากําไรด้วยทรัพยากรที่วางแผนไว้ได้เพียงใด",
    category: "Operation",
    MainCategory: "POS",
  },
  {
    question: "ธุรกิจมีแผนบริหารความเสี่ยง/โอกาสในการดําเนินงานเพียงใด",
    category: "Operation",
    MainCategory: "POS",
  },
  {
    question: "ธุรกิจมีการบริหารจัดการมาตรฐานคุณภาพของผลิตภัณฑ์/บริการ เพียงใด",
    category: "Operation",
    MainCategory: "POS",
  },
  {
    question:
      "ธุรกิจมีการดูแลรักษาข้อมูลทางอิเล็กทรอนิกส์ที่สําคัญ รวมทั้งการบวนการนำข้อมูลมาใช้ และการป้องกันข้อมูลเพียงใด",
    category: "Information",
    MainCategory: "POS",
  },
  {
    question:
      "ธุรกิจมีการนำข้อมูลมาสนับสนุนเพื่อให้เกิดการบูรณาการของแผนงานเข้าด้วยกันของทุกหน่วยงานเพียงใด",
    category: "Information",
    MainCategory: "POS",
  },
  {
    question:
      "ธุรกิจมีแผนการกู้คืนข้อมูล หากธุรกิจได้รับผลกระทบที่เกิดภัยพิบัติขึ้นเพียงใด",
    category: "Information",
    MainCategory: "POS",
  },
  {
    question:
      "ธุรกิจมีการปรับปรุงกระบวนการขาย และการตลาดที่จะเพิ่มโอกาสในการขายสินค้า/บริการเพียงใด",
    category: "Sale and Marketing",
    MainCategory: "MKT",
  },
  {
    question:
      "ธุรกิจมีการกำหนดทิศทางเชิงกลยุทธ์เพื่อสอดคล้องกับทิศทางของตลาดมากน้อยเพียงใด",
    category: "Sale and Marketing",
    MainCategory: "MKT",
  },
  {
    question: "ธุรกิจมีโมเดลธุรกิจที่สามารถเติบโตอย่างยั่งยืนเพียงใด",
    category: "Sale and Marketing",
    MainCategory: "MKT",
  },
  {
    question:
      "ธุรกิจของคุณมีบุคลากรที่มีความรู้ ทักษะที่จําเป็นในการดําเนินธุรกิจให้ประสบความสําเร็จเพียงใด",
    category: "Organizational",
    MainCategory: "PEO",
  },
  {
    question:
      "ธุรกิจมีกระบวนการ และขั้นตอนกระบวนการตอนในการรักษาทรัพย์สินทางปัญญาที่เป็รลัหษณะเฉพาะของธุรกิจมากน้อยเพียงใด",
    category: "Organizational",
    MainCategory: "PEO",
  },
  {
    question:
      "ธุรกิจมีการกำหนดวิสัยทัศน์เชิงกลยุทธที่กําหนดไว้อย่างชัดเจน เพื่อสามารถตอบสนองความท้าทายที่อาจเกิดขึ้นในการเติบโตของธุรกิจเพียงใด",
    category: "Organizational",
    MainCategory: "PEO",
  },
  {
    question:
      "ธุรกิจมีมีนโยบายควบคุม และประเมินผลการปฏิบัติงานของพนักงานอย่างมีประสิทธิภาพเพียงใด",
    category: "Personnel",
    MainCategory: "PEO",
  },
  {
    question: "ธุรกิจมีแผนการรักษาบุคลากรเพียงใด",
    category: "Personnel",
    MainCategory: "PEO",
  },
  {
    question: "ธุรกิจมีการวัดประสิทธิภาพการฝึกอบรมเพียงใด",
    category: "Personnel",
    MainCategory: "PEO",
  },
  {
    question:
      "ธุรกิจมีแนวโน้มรายได้ ค่าใช้จ่าย ที่สะท้อน กำรหรือขาดทุน (P&L) แสดงถึงความเสี่ยงหรือในโอกาสในการดําเนินงานเพียงใด",
    category: "Financial",
    MainCategory: "FIN",
  },
  {
    question:
      "ธุรกิจมีการคาดการณ์ รายได้อย่างน้อย 3 ปี ข้างหน้าอย่างมีประสิทธิภาพเพียงใด",
    category: "Financial",
    MainCategory: "FIN",
  },
  {
    question:
      "ธุรกิจมีการจัดสรรงบประมาณในแต่ละหน่วยงานอย่างมีประสิทธิภาพเพียงใด",
    category: "Financial",
    MainCategory: "FIN",
  },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function BusinessAssessment() {
  const [answers, setAnswers] = useState({});
  const [result, setResult] = useState({});
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const questionRefs = useRef([]);
  const [percentages, setPercentages] = useState({
    MKT: 0,
    POS: 0,
    FIN: 0,
    PEO: 0,
  });
  const [BSCMessage, setBSCMessage] = useState("");
  const [BSC, setBSC] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [suggestedSolutions, setSuggestedSolutions] = useState([]);

  const steps = ["Results Overview", "BSC Result", "Thank You"];

  useEffect(() => {
    setShuffledQuestions(shuffleArray([...questions]));
  }, []);

  useEffect(() => {
    const completed = Object.keys(answers).length;
    setProgress((completed / questions.length) * 100);
  }, [answers]);

  const handleAnswerChange = (index, value) => {
    setAnswers((prev) => ({
      ...prev,
      [index]: parseInt(value, 10),
    }));
  };

  const calculateResults = async () => {
    const unansweredIndex = shuffledQuestions.findIndex(
      (_, index) => answers[index] == null
    );

    if (unansweredIndex !== -1) {
      setError("กรุณาตอบคำถามให้ครบทุกข้อ");
      questionRefs.current[unansweredIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    setError("");

    let scores = {
      MKT: 0,
      POS: 0,
      FIN: 0,
      PEO: 0,
    };

    shuffledQuestions.forEach((q, index) => {
      const mainCategory = q.MainCategory;
      if (answers[index] != null) {
        scores[mainCategory] += answers[index];
      }
    });

    const maxScores = {
      MKT: 30,
      POS: 30,
      FIN: 15,
      PEO: 30,
    };

    const percentages = {
      MKT: (scores.MKT / maxScores.MKT) * 100,
      POS: (scores.POS / maxScores.POS) * 100,
      FIN: (scores.FIN / maxScores.FIN) * 100,
      PEO: (scores.PEO / maxScores.PEO) * 100,
    };

    const BSC =
      (parseFloat(percentages.MKT.toFixed(2)) +
        parseFloat(percentages.POS.toFixed(2)) +
        parseFloat(percentages.FIN.toFixed(2)) +
        parseFloat(percentages.PEO.toFixed(2))) /
      4;

    let BSCMessage = "";
    if (BSC >= 0 && BSC <= 60) {
      BSCMessage =
        "แสดงให้เห็นถึงความท้าทายและปัญหาที่ต้องการการปรับปรุงในด้านการเงินอย่างเร่งด่วน ทั้งในเรื่องของการบริหารจัดการรายได้และค่าใช้จ่าย การคาดการณ์รายได้ในอนาคต และการจัดสรรงบประมาณให้มีประสิทธิภาพ การปรับปรุงในด้านเหล่านี้จะช่วยเพิ่มความสามารถในการแข่งขันและสนับสนุนการเติบโตขององค์กรในอนาคต";
    } else if (BSC >= 61 && BSC <= 70) {
      BSCMessage =
        "แสดงให้เห็นถึงความสามารถในการบริหารจัดการทางการเงินในระดับที่ค่อนข้างดี แต่ยังมีช่องว่างสำหรับการพัฒนาและปรับปรุงในด้านการวิเคราะห์แนวโน้มรายได้ ค่าใช้จ่าย การคาดการณ์รายได้ในอนาคต และการจัดสรรงบประมาณให้มีประสิทธิภาพมากยิ่งขึ้น การดำเนินการปรับปรุงในด้านเหล่านี้จะช่วยสนับสนุนความสำเร็จในอนาคตขององค์กร";
    } else if (BSC >= 71 && BSC <= 80) {
      BSCMessage =
        "แสดงให้เห็นถึงความสามารถในการบริหารจัดการทางการเงินที่ดี มีการควบคุมรายได้และค่าใช้จ่ายอย่างเหมาะสม พร้อมทั้งการคาดการณ์รายได้ในอนาคตที่มีประสิทธิภาพ อย่างไรก็ตาม ยังมีพื้นที่สำหรับการพัฒนาในด้านการบริหารความเสี่ยง การปรับปรุงระบบการคาดการณ์ และการติดตามผลการจัดสรรงบประมาณ เพื่อเพิ่มศักยภาพในการเติบโตอย่างยั่งยืนในอนาคต";
    } else if (BSC >= 81 && BSC <= 100) {
      BSCMessage =
        "แสดงให้เห็นถึงความสามารถในการบริหารจัดการทางการเงินที่ยอดเยี่ยม มีการควบคุมรายได้และค่าใช้จ่ายอย่างมีประสิทธิภาพ รวมถึงการคาดการณ์รายได้ในอนาคตที่แม่นยำและการจัดสรรงบประมาณที่มีประสิทธิภาพ สิ่งเหล่านี้ทำให้ธุรกิจสามารถเติบโตได้อย่างยั่งยืนและตอบสนองต่อความท้าทายได้อย่างดี ทำให้มั่นใจได้ว่าธุรกิจมีความสามารถในการขยายตัวและสร้างผลกำไรในระยะยาว";
    }

    const Solution = {
      MKT: [
        {
          name: "Customer Satisfaction",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Business Innovation Program",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Business Strategy Workshop",
          link: "https://www.gpbs.co.th/service/",
        },
      ],
      POS: [
        {
          name: "Crisis Management Workshop",
          link: "https://www.gpbs.co.th/service/",
        },
        { name: "Business War Room", link: "https://www.gpbs.co.th/service/" },
        { name: "ISO/IEC 27001", link: "https://www.gpbs.co.th/service/" },
        {
          name: "Cross Functional Evaluation",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Business Innovation Program",
          link: "https://www.gpbs.co.th/service/",
        },
      ],
      FIN: [
        {
          name: "Business Development Software",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Business Simulation Game Workshop",
          link: "https://www.gpbs.co.th/service/",
        },
      ],
      PEO: [
        {
          name: "Intelligent People Solution",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Employee Engagement Survey",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Business Strategy Workshop",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "Knowledge Management",
          link: "https://www.gpbs.co.th/service/",
        },
        {
          name: "360 Degree Feedback",
          link: "https://www.gpbs.co.th/service/",
        },
      ],
    };

    const suggestedSolutions = [];

    if (percentages.MKT < 60) {
      suggestedSolutions.push(...Solution.MKT);
    }
    if (percentages.POS < 60) {
      suggestedSolutions.push(...Solution.POS);
    }
    if (percentages.FIN < 60) {
      suggestedSolutions.push(...Solution.FIN);
    }
    if (percentages.PEO < 60) {
      suggestedSolutions.push(...Solution.PEO);
    }

    setResult(scores);
    setBSC(parseFloat(BSC.toFixed(2)));
    setBSCMessage(BSCMessage);
    setPercentages(percentages);
    setSuggestedSolutions(suggestedSolutions);
    setOpenModal(true);

    const companyData = JSON.parse(localStorage.getItem("companyData"));

    if (!companyData) {
      console.error("Company data not found in localStorage");
      return;
    }

    const dataToSend = {
      newData: {
        firstName: companyData.firstName,
        lastName: companyData.lastName,
        position: companyData.position,
        department: companyData.department,
        companyName: companyData.companyName,
        companyPhone: companyData.companyPhone,
        companyEmail: companyData.companyEmail,
        businessType: companyData.businessType,
        developmentDimension: companyData.developmentDimension,
        MKT: scores.MKT,
        POS: scores.POS,
        FIN: scores.FIN,
        PEO: scores.PEO,
      },
    };

    try {
      const response = await fetch(
        "https://bizscan.gpbsapps.com/insertCompanyAssessment",
        // "http://localhost:3001/insertCompanyAssessment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Insert successful:", result);

      localStorage.removeItem("companyData");
      console.log("Local storage cleared.");
    } catch (error) {
      console.error("Error inserting company assessment:", error);
    }
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setActiveStep(0);
  };

  React.useEffect(() => {
    document.body.style.background =
      "linear-gradient(90deg, rgba(255,180,141,1) 0%, rgba(212,251,127,1) 35%, rgba(165,251,255,1) 100%)";
    document.body.style.margin = "0";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.background = "";
      document.body.style.margin = "";
      document.body.style.height = "";
    };
  }, []);

  const data = {
    labels: Object.keys(result).map((key) => {
      switch (key) {
        case "MKT":
          return "Marketing";
        case "POS":
          return "Operations";
        case "FIN":
          return "Financial";
        case "PEO":
          return "Personnel";
        default:
          return key;
      }
    }),
    datasets: [
      {
        label: "คะแนน",
        data: Object.values(result),
        backgroundColor: blue[300],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "คะแนนการประเมินในแต่ละหมวดหมู่หลัก" },
      annotation: {
        annotations: {
          MKT: {
            type: "line",
            yMin: 18,
            yMax: 18,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Minimum",
              enabled: true,
              position: "top",
            },
          },
          POS: {
            type: "line",
            yMin: 18,
            yMax: 18,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Minimum",
              enabled: true,
              position: "top",
            },
          },
          FIN: {
            type: "line",
            yMin: 9,
            yMax: 9,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Minimum",
              enabled: true,
              position: "top",
            },
          },
          PEO: {
            type: "line",
            yMin: 18,
            yMax: 18,
            borderColor: "red",
            borderWidth: 2,
            label: {
              content: "Minimum",
              enabled: true,
              position: "top",
            },
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          max: 30,
        },
        suggestedMax: 30,
      },
    },
  };

  return (
    <Box sx={{ padding: 4, background: "#2F3B4C", minHeight: "100vh" }}>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
        <img
          src={gpbsLogo}
          alt="Company Logo"
          style={{ maxWidth: "150px", height: "auto" }}
        />
      </Box>
      <Box sx={{ maxWidth: "900px", margin: "0 auto", textAlign: "center" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", color: "white" }}
        >
          Business Assessment
        </Typography>

        {error && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>
        )}

        {shuffledQuestions.map((q, index) => (
          <Paper
            key={index}
            elevation={2}
            sx={{
              padding: 3,
              marginBottom: 2,
              backgroundColor: "#fff",
              borderRadius: "12px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
            ref={(el) => (questionRefs.current[index] = el)}
          >
            <Typography variant="h6" gutterBottom style={{ color: "#576071" }}>
              {q.question}
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name={`question-${index}`}
                value={answers[index] || ""}
                onChange={(e) => handleAnswerChange(index, e.target.value)}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<></>}
                  label={"1"}
                  sx={{
                    marginRight: {
                      xs: 0.5,
                      sm: 1,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: {
                        xs: 20,
                        sm: 35,
                      },
                      color: "#DE3163",
                    },
                  }}
                />

                {[1, 2, 3, 4, 5].map((value) => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: {
                              xs:
                                value === 1 || value === 5
                                  ? 40
                                  : value === 2 || value === 4
                                  ? 25
                                  : 15,
                              sm:
                                value === 1 || value === 5
                                  ? 60
                                  : value === 2 || value === 4
                                  ? 45
                                  : 30,
                            },
                            color:
                              value === 5 || value === 4
                                ? "#9FE2BF"
                                : value === 3
                                ? "#FFBF00"
                                : "#DE3163",
                          },
                        }}
                      />
                    }
                    label={""}
                    sx={{
                      marginRight: {
                        xs: value !== 5 ? 0.5 : 0,
                        sm: value !== 5 ? 3 : 0,
                      },
                    }}
                  />
                ))}

                <FormControlLabel
                  value={5}
                  control={<></>}
                  label={"5"}
                  sx={{
                    marginLeft: {
                      xs: 0.5,
                      sm: 1,
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: {
                        xs: 20,
                        sm: 35,
                      },
                      color: "#9FE2BF",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Paper>
        ))}

        <Button
          variant="contained"
          color="primary"
          onClick={calculateResults}
          style={{ backgroundColor: "#f68c4b" }}
          sx={{ paddingX: 5, paddingY: 1.5, fontSize: "1.1rem" }}
        >
          Get Results
        </Button>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="results-modal-title"
        aria-describedby="results-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "700px",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ marginBottom: "50px" }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 && (
            <>
              <Bar data={data} options={options} />
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" sx={{ color: "#2F3B4C" }}>
                  Percentages by Category:
                </Typography>
                <Typography variant="body1" sx={{ color: "#576071" }}>
                  Marketing: {percentages.MKT.toFixed(2)}%
                </Typography>
                <Typography variant="body1" sx={{ color: "#576071" }}>
                  Operations: {percentages.POS.toFixed(2)}%
                </Typography>
                <Typography variant="body1" sx={{ color: "#576071" }}>
                  Financial: {percentages.FIN.toFixed(2)}%
                </Typography>
                <Typography variant="body1" sx={{ color: "#576071" }}>
                  Personnel: {percentages.PEO.toFixed(2)}%
                </Typography>
              </Box>
            </>
          )}

          {activeStep === 1 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" sx={{ color: "#2F3B4C" }}>
                BSC Result: {BSC}%
              </Typography>
              <Typography variant="body1" sx={{ color: "#576071", mt: 1 }}>
                {BSCMessage}
              </Typography>
            </Box>
          )}

          {activeStep === 2 && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" sx={{ color: "#2F3B4C" }}>
                ทางเรามีสินค้ามาแนะนำ หากสนใจติดต่อได้ที่เบอร์ 088-662-9322
              </Typography>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={12} sm={6}>
                  {suggestedSolutions.length > 0 && (
                    <div>
                      <Typography variant="h6" sx={{ mb: 2, color: "#2F3B4C" }}>
                        สินค้าแนะนำ:
                      </Typography>
                      <ul style={{ padding: 0, listStyleType: "none" }}>
                        {suggestedSolutions.map((solution, index) => (
                          <li key={index} style={{ marginBottom: "10px" }}>
                            <a
                              href={solution.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "#0288d1",
                                fontWeight: 500,
                                transition: "color 0.3s",
                              }}
                              onMouseEnter={(e) =>
                                (e.target.style.color = "#01579b")
                              }
                              onMouseLeave={(e) =>
                                (e.target.style.color = "#0288d1")
                              }
                              onFocus={(e) =>
                                (e.target.style.color = "#01579b")
                              }
                            >
                              {solution.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <h3 style={{ marginLeft: "50px" }}>
                    แคปภาพหน้านี้เพื่อเก็บส่วนลด
                  </h3>
                  <img
                    src={voucher}
                    style={{
                      height: "350px",
                      width: "auto",
                      display: "block",
                      marginTop: "20px",
                      marginLeft: "50px",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "" : "Next"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Tooltips
        title={
          <React.Fragment>
            <Typography
              sx={{
                fontSize: "14px",
                paddingTop: 2,
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              1 : น้อยที่สุด
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              2 : น้อย
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              3 : ปานกลาง
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              4 : มาก
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                paddingBottom: 2,
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              5 : มากที่สุด
            </Typography>
          </React.Fragment>
        }
        placement="top"
        arrow
      >
        <Fab
          color="info"
          sx={{
            position: "fixed",
            bottom: 16,
            left: 40,
            backgroundColor: "#f68c4b",
          }}
        >
          <InfoIcon />
        </Fab>
      </Tooltips>
    </Box>
  );
}

export default BusinessAssessment;

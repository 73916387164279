import React, { useState } from "react";
import { TextField, Button, Box, Typography, Paper, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import gpbsLogo from "../assets/gpbslogo.png";

function CompanyForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    position: "",
    department: "",
    companyName: "",
    companyPhone: "",
    companyEmail: "",
    businessType: "",
    developmentDimension: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNext = (e) => {
    e.preventDefault();
    localStorage.setItem("companyData", JSON.stringify(formData));
    navigate("/business-assessment");
  };

  React.useEffect(() => {
    document.body.style.background = "#2F3B4C";
    document.body.style.margin = "0";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.background = "";
      document.body.style.margin = "";
      document.body.style.height = "";
    };
  }, []);

  return (
    <div>
      <Box
        sx={{
          background: "#2F3B4C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            padding: 4,
            borderRadius: "15px",
            maxWidth: 600,
            width: "100%",
            boxSizing: "border-box",
            overflow: "auto",
          }}
        >
          {/* Logo */}
          <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
            <img
              src={gpbsLogo}
              alt="Company Logo"
              style={{ maxWidth: "150px", height: "auto" }}
            />
          </Box>

          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold", mb: 4 }}
            style={{ color: "#446084" }}
          >
            Company Information
          </Typography>
          <form onSubmit={handleNext}>
            <Grid container spacing={3}>
              {[
                { name: "firstName", label: "ชื่อ" },
                { name: "lastName", label: "นามสกุล" },
                { name: "position", label: "ตำแหน่ง" },
                { name: "department", label: "หน่วยงาน" },
                { name: "companyName", label: "บริษัท" },
              ].map((field, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <TextField
                    fullWidth
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    type="text"
                    InputLabelProps={{
                      sx: {
                        color: "#f68c4b", // Floating label color when inactive
                        "&.Mui-focused": {
                          color: "#2F3B4C",
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: "#2F3B4C", // Text color
                      },
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "black", // Floating label color when inactive
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#f68c4b", // Floating label color when focused
                      },
                      "& .MuiInputBase-input": {
                        color: "#2F3B4C", // Input text color
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#f68c4b", // Border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2F3B4C", // Border color on hover
                      },
                    }}
                  />
                </Grid>
              ))}

              {/* Company Phone Field with Numeric and Length Validation */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="เบอร์ติดต่อ"
                  name="companyPhone"
                  value={formData.companyPhone}
                  onChange={(e) => {
                    // Only allow numbers and limit the input to 10 characters
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      setFormData({
                        ...formData,
                        companyPhone: value,
                      });
                    }
                  }}
                  required
                  variant="outlined"
                  size="small"
                  type="tel"
                  inputProps={{
                    minLength: 9,
                    maxLength: 10,
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "#f68c4b", // Floating label color when inactive
                      "&.Mui-focused": {
                        color: "#2F3B4C", // Floating label color when focused
                      },
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#2F3B4C", // Text color
                    },
                  }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "black", // Floating label color when inactive
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#f68c4b", // Floating label color when focused
                    },
                    "& .MuiInputBase-input": {
                      color: "#2F3B4C", // Input text color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68c4b", // Border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#2F3B4C", // Border color on hover
                    },
                  }}
                />
              </Grid>

              {[
                { name: "companyEmail", label: "Email", type: "email" },
                { name: "businessType", label: "ประเภทธุรกิจ" },
                {
                  name: "developmentDimension",
                  label: "มิติที่ต้องการพัฒนาบริษัท",
                  multiline: true,
                  rows: 3,
                },
              ].map((field, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    fullWidth
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    type={field.type || "text"}
                    multiline={field.multiline || false}
                    rows={field.rows || 1}
                    InputLabelProps={{
                      sx: {
                        color: "#f68c4b", // Floating label color when inactive
                        "&.Mui-focused": {
                          color: "#2F3B4C", // Floating label color when focused
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: "#2F3B4C", // Text color
                      },
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "black", // Floating label color when inactive
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#f68c4b", // Floating label color when focused
                      },
                      "& .MuiInputBase-input": {
                        color: "#2F3B4C", // Input text color
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#f68c4b", // Border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#2F3B4C", // Border color on hover
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ backgroundColor: "#f68c4b" }}
                sx={{ paddingX: 5, paddingY: 1.5, fontSize: "1.1rem" }}
              >
                Next
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>
    </div>
  );
}

export default CompanyForm;
